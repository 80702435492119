import { Camera, CameraResultType, CameraSource, CameraPermissionType } from "@capacitor/camera";
import {
  IonFabButton,
  IonFabList,
  IonIcon,
  IonLoading,
  isPlatform,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { camera, cloudUpload, push } from "ionicons/icons";
import { useContext, useState } from "react";
import {
  useDocumentManager,
  FileOptions,
  PhotoOptions,
  UploadResult,
} from "../hooks/useDocumentManager";
import { TranslationsContext } from "../util/Translations";

export interface UploadOptions {
  WoNumber: string;
  cameraEnabled: boolean;
  fileEnabled: boolean;
  isSmall: boolean;
  processCompletedCallback: (isSuccess: boolean) => void;
}

const requestPermissions = async () => {
  const permissions = await Camera.requestPermissions({
    permissions: ["camera", "photos"],
  });
  return permissions;
};

const checkPermissions = async () => {
  const permissions = await Camera.checkPermissions();
  return permissions;
};

const FileUploadFAB = (options: UploadOptions) => {
  const { translations } = useContext(TranslationsContext);
  const {
    WoNumber,
    cameraEnabled,
    fileEnabled,
    isSmall,
    processCompletedCallback,
  } = options;
  const { uploadFile, uploadPhoto } = useDocumentManager();
  const [showLoading, setShowLoading] = useState(false);
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();

  const selectFile = async () => {
    // invoke the hidden input's click event
    (document as any).getElementById("filePicker").click();
  };

  const fileSelected = async (event: any) => {
    //get file name and extension
    const fileName = event.target.files[0].name.substring(
      0,
      event.target.files[0].name.lastIndexOf(".")
    );
    const fileExtn = event.target.files[0].name.substring(
      event.target.files[0].name.lastIndexOf(".") + 1
    );
    presentAlert({
      message: translations["lbl_enter_file_name"] || "Please enter file name",
      backdropDismiss: false,
      buttons: [
        {
          text: translations["lbl_btn_ok"] || "OK",
          async handler(value) {
            if (!!value && value[0] !== "") {
              setShowLoading(true);
              const options: FileOptions = {
                WoNumber: WoNumber,
                File: event.target.files[0],
                FileName: `${value[0]}.${fileExtn}`,
                showLoadingCallback(showYn) {
                  setShowLoading(showYn);
                },
                uploadDoneCallback,
              };
              uploadFile(options);
              return;
            }
            return false;
          },
        },
        {
          text: translations["lbl_btn_cancel"] || "Cancel",
          role: "Cancel",
        },
      ],
      inputs: [
        {
          id: "txtFileName",
          placeholder: translations["lbl_file_name"] || "File Name",
          attributes: {
            maxlength: 100,
          },
          value: fileName,
        },
      ],
      onDidPresent(event) {
        //pre-select the name so that its easier for the user to edit
        (document.getElementById("txtFileName") as HTMLInputElement).select();
      },
    });
  };

  const fileClicked = async () => {
    (document as any).getElementById("filePicker").value = null;
  };

  const uploadDoneCallback = async (result: UploadResult) => {
    const message =
      typeof result.Response === "string"
        ? result.Response
        : result.Response?.Message;
    if (result.Success) {
      showToast(message);
    } else {
      showAlert(message);
    }
    processCompletedCallback(result.Success);
  };

  const takePhoto = async () => {
    const permissions = await checkPermissions();
    if (permissions.camera !== 'granted' || permissions.photos !== 'granted') {
      await requestPermissions();
    }
    const source = await presentCustomPhotoSourceDialog();
    if(source !== null) {
      await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        allowEditing: false,
        quality: 100,
        source: source as CameraSource,
      }).then(async (photo) => {
        presentAlert({
          message:
            translations["lbl_enter_file_name"] || "Please enter file name",
          backdropDismiss: false,
          buttons: [
            {
              text: translations["lbl_btn_ok"] || "OK",
              async handler(value) {
                if (!!value && value[0] !== "") {
                  setShowLoading(true);
                  const options: PhotoOptions = {
                    WoNumber: WoNumber,
                    Photo: photo,
                    FileName: `${value[0]}.${photo.format}`,
                    showLoadingCallback(showYn) {
                      setShowLoading(showYn);
                    },
                    uploadDoneCallback,
                  };
                  uploadPhoto(options);
                  return;
                }
                return false;
              },
            },
            {
              text: translations["lbl_btn_cancel"] || "Cancel",
              role: "Cancel",
            },
          ],
          inputs: [
            {
              placeholder: translations["lbl_file_name"] || "File Name",
              attributes: {
                maxlength: 100,
              },
            },
          ],
        });
      });
    }
  };

  const presentCustomPhotoSourceDialog = async () => {
    const promise = new Promise<CameraSource | null>((resolve) => {
      presentAlert({
        header: 'Choose Photo Source',
        message: 'Do you want to take a new photo or select an existing one?',
        buttons: [
          {
            text: 'Take Picture',
            handler: () => {
              console.log('Take Picture clicked');
              resolve(CameraSource.Camera);
            },
          },
          {
            text: 'From Photos',
            handler: () => {
              console.log('From Photos clicked');
              resolve(CameraSource.Photos);
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
              resolve(null);
            },
          },
        ],
      });
    });
  
    return promise;
  };

  const showToast = (message: string) => {
    presentToast({
      message: message,
      duration: 5000,
      position: "top",
    });
  };

  const showAlert = (message: string) => {
    presentAlert({
      message: message,
      buttons: ["OK"],
    });
  };

  return (
    <>
      <input
        hidden
        type="file"
        id="filePicker"
        onChange={fileSelected}
        onClick={fileClicked}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      {cameraEnabled && fileEnabled ? (
        isPlatform("ios") ? (
          <IonFabButton
            color="primary"
            hidden={!cameraEnabled && !fileEnabled}
            onClick={() => selectFile()}
          >
            <IonIcon icon={cloudUpload}></IonIcon>
          </IonFabButton>
        ) : (
          <>
            <IonFabButton color="primary">
              <IonIcon icon={push}></IonIcon>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton
                color="tertiary"
                hidden={!cameraEnabled}
                onClick={() => takePhoto()}
              >
                <IonIcon icon={camera}></IonIcon>
              </IonFabButton>
              <IonFabButton color="tertiary" onClick={() => selectFile()}>
                <IonIcon icon={cloudUpload}></IonIcon>
              </IonFabButton>
            </IonFabList>
          </>
        )
      ) : cameraEnabled ? (
        <IonFabButton
          color="primary"
          size={isSmall ? "small" : undefined}
          onClick={() => takePhoto()}
        >
          <IonIcon icon={camera}></IonIcon>
        </IonFabButton>
      ) : fileEnabled ? (
        <IonFabButton
          color="primary"
          size={isSmall ? "small" : undefined}
          onClick={() => selectFile()}
        >
          <IonIcon icon={cloudUpload}></IonIcon>
        </IonFabButton>
      ) : (
        <></>
      )}
    </>
  );
};

export default FileUploadFAB;
