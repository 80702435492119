import { IonContent, IonPage, IonLoading } from "@ionic/react";
import PageHeader from "../components/PageHeader";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { GetScheduleForEmployee } from "../api/Scheduling";
import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserId, getUserPlantId } from "../util/UserHelper";
import { CalendarEvent } from "../api/Scheduling";
import './MySchedule.css';

const MySchedule: React.FC = () => {
    const calendarRef = useRef(null);
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [minSlotTime, setMinSlotTime] = useState("00:00:00");
    const [selectedView, setSelectedView] = useState("timeGridDay");

    const handleEventClick = (info: any) => {
        const woBase = info.event.title.split(' : ')[0];
        history.push(`/workorder/${woBase}`);
    }

    const getEmployee = async () => {
        const response = await getUserId();
        if (response !== null) {
            return response;
        }
    };

    useEffect(() => {
        updateMinSlotTime(selectedDate);
    }, [selectedDate, events]);

    useEffect(() => {
        let isMounted = true;
        setShowLoading(true);
        GetScheduleDataForEmployee(isMounted, selectedDate);

        return () => { isMounted = false };
    }, [selectedDate, history.location]);

    const getLoggedInPlant = async () => {
        return await getUserPlantId().then((response) => {
            if (response !== null) {
                return response;
            }
        });
    };

    const GetScheduleDataForEmployee = async (isMounted: boolean, date: Date) => {
        const logInEmployee = await getEmployee();
        const plant = await getLoggedInPlant() || '';
        const today = new Date(date);
        const nextDate = new Date(today);
        let currentDate = today.getDate();
        nextDate.setDate(currentDate + 1);
        if (selectedView === 'timeGridWeek') {
            nextDate.setDate(currentDate + 6);
        } else {
            nextDate.setDate(currentDate + 1);
        }
        const formattedToday = new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).format(today);

        const formattedTomorrow = new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).format(nextDate);
        if (logInEmployee) {
            const response = await GetScheduleForEmployee(plant, formattedTomorrow, formattedToday, logInEmployee);
            const transformedData = response.data.map((event: { wo_base: any; description: any; assigned_date: string; hours: number, status: any}) => {
                const startDate = new Date(event.assigned_date);
                const endDate = new Date(startDate);
                const hoursToAdd = event.hours;
                endDate.setHours(startDate.getHours() + hoursToAdd);

                return {
                    title: `${event.wo_base} : ${event.description}`,
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    backgroundColor: event.status === 70 ? 'grey' : 'blue'
                };
            });
            if (isMounted) {
                setEvents(transformedData);
                setShowLoading(false);
            }
        }
    };

    const updateMinSlotTime = (date: Date) => {
        // Find the earliest event for the selected day
        const selectedDayEvents = events.filter(event => new Date(event.start).toDateString() === date.toDateString());
        if (selectedDayEvents.length > 0) {
            const earliestEventSelectedDay = selectedDayEvents.reduce((earliest, current) => new Date(current.start) < new Date(earliest.start) ? current : earliest, selectedDayEvents[0]);
            const eventStartDate = new Date(earliestEventSelectedDay.start);
            eventStartDate.setMinutes(0, 0, 0);
            setMinSlotTime(eventStartDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }));
        }
        else {
            setMinSlotTime("00:00:00");
        }
    };

    return (
        <IonPage>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
            />
            <PageHeader title="My Schedule" />
            <IonContent>
                <div className="calendar-container">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        initialView='timeGridDay'
                        events={events}
                        height="auto"
                        slotMinTime={minSlotTime}
                        headerToolbar={{
                            start: 'prev,today,next',
                            center: 'title',
                            end: 'timeGridWeek,timeGridDay'
                        }}
                        eventClick={handleEventClick}
                        datesSet={(info) => {
                            setSelectedDate(info.start);
                            setSelectedView(info.view.type);
                        }}
                    />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default MySchedule;