import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonFooter
} from "@ionic/react";
import React, { useContext, useEffect, useState, useRef } from "react";
import { TranslationsContext } from "../util/Translations";
import "./WOApprovalModal.css";
import { Requisition, WorkOrderMetadata, getAllFields } from "../models/workorders/WorkOrder";
import { processRequisitions } from "../api/Requisitions";
import { checkmarkCircleOutline, chevronDownOutline, filterCircleOutline, filterCircleSharp, searchOutline, searchSharp } from "ionicons/icons";
import ReqApprovalLogActivity from "./ReqApprovalLogActivity";
import FilterModal from "./FilterModal";
import { ApiResponse } from "../models/api/ApiResponse";
import { getValueSearchDetails } from "../api/QueryDesigner";
import { findEmployee } from "../api/Employees";
import ISSPopover from "./ISSPopover";

interface ReqModalProps {
  isOpen: boolean;
  cancel: () => void;
  approve: (status: string) => void;
  review: (status: string) => void;
  reject: (status: string) => void;
  cardHeader: string;
  metadata: WorkOrderMetadata;
  requisition: Requisition[];
  setRequisition: (requisition: Requisition[]) => void;
  nextReviewer: string;
  setNextReviewer: (nextReviewer: string) => void;
  setShowLoading: (showLoading: boolean) => void;
  backdropDismiss: false;
}

interface ValidValues {
  Code: string;
  Description: string;
}

const ReqApprovalModal: React.FC<ReqModalProps> = (props) => {
  const {
    isOpen,
    cancel,
    approve,
    review,
    reject,
    cardHeader,
    metadata,
    requisition,
    setRequisition,
    nextReviewer,
    setNextReviewer,
    setShowLoading,
    backdropDismiss,
  } = props;
  const { translations } = useContext(TranslationsContext);
  const [comments, setComments] = useState<string>("");
  const [reviewerList, setReviewerList] = useState<ValidValues[]>([]);
  const [showLogActivity, setShowLogActivity] = useState(false);
  const [lineNum, setLineNum] = useState<number>(0);
  const approveAction = "approve";
  const reviewAction = "review";
  const rejectAction = "reject";
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState<string>('');
  const [filterModalColumnName, setFilterModalColumnName] = useState("");
  const [filterModalModuleName, setFilterModalModuleName] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  let [filteredReviewerList] = useState<ValidValues[]>([]);

  const validateCheckedList = async (action: string) => {
    const checkedList = requisition.filter((card) => {
      return card.checked;
    });
    if (checkedList.length === 0) {
      return false;
    }
    for (const card of checkedList) {
      processRequisition(action, nextReviewer, card.LineNum, cardHeader);
    }
  };

  if (metadata?.fields["in_req_line.next_reviewer"].ElementType !== 'ISS') {
    filteredReviewerList = reviewerList.filter(card =>
      card.Code.toLowerCase().includes(filter.toLowerCase()) || card.Description.toLowerCase().includes(filter.toLowerCase())
    );
  }

  const checkEmpty = () => {
    const checkedList = requisition.filter((card) => {
      return card.checked;
    });
    if (checkedList.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const processRequisition = async (
    action: string,
    nextReviewer: string,
    reqLine: number,
    requisition: string
  ) => {
    setShowLoading(true);
    const requisitionData = [];
    const data = {
      Action: action,
      Comments: comments,
      NextReviewer: nextReviewer,
      ReqLine: reqLine,
      Requisition: requisition,
    };
    requisitionData.push(data);
    processRequisitions(requisitionData).then((response) => {
      handleStatusMessage(
        action,
        response.data?.ReqInfoResult[0]?.MessageList[0]?.Text
      );
    });
  };

  const handleStatusMessage = async (action: string, status: string) => {
    if (action === approveAction) {
      return approve(status);
    }
    return action === reviewAction ? review(status) : reject(status);
  };

  const getReviewerList = async () => {
    setReviewerList(metadata?.fields["in_req_line.next_reviewer"]?.ValidValues);
  };

  const onSelect = (checked: boolean, lineNum: number) => {
    const newArray = requisition.map((card) => {
      if (card.LineNum === lineNum) {
        return {
          ...card,
          checked: checked,
        };
      }
      return card;
    });
    setRequisition(newArray);
  };

  const onSelectAllClick = () => {
    onSelectAll(requisition.map((card) => card.LineNum));
  };

  const onSelectAll = async (card: number[]) => {
    const newArray = requisition.map((entry) => {
      return { ...entry, checked: card.includes(entry.LineNum) };
    });
    setRequisition(newArray);
  };

  useEffect(() => {
    getReviewerList();
  }, []);

  const handleRadioChange = (e: any) => {
    setSelectedReviewer(e.detail.value);
  };

  const handleOk = () => {
    setNextReviewer(selectedReviewer);
    setShowModal(false);
  };

  const handleClose = () => {
    setSelectedReviewer(nextReviewer); // Reset selected reviewer
    setShowModal(false);
  };

  const resetSearchState = () => {
    setFilterModalColumnName('');
    setFilterModalModuleName('');
  };

  const onFilterModalConfirm = (selectedItem: string | number | null | undefined) => {
    setParameter(filterModalColumnName, selectedItem);
    setIsFilterModalOpen(false);
    resetSearchState();
  };

  const setParameter = (
    paramKey: string,
    paramValue: string | number | null | undefined
  ) => {
    if (paramKey === 'employee_id') {
      setNextReviewer(paramValue as string);
    }
  };

  const handleEmployeeSearch = (e: CustomEvent) => {
    let newVal = (e.target as HTMLInputElement)?.value;
    setNextReviewer(newVal);
    if (newVal.length > 2) {
      findEmployee(newVal).then((response) => {
        if (response.status === 200) {
          if (Object.keys(response.data).length !== 0) {
            popover.current!.event = e;
            setPopoverOpen(true);
          } else if (popoverOpen) {
            setPopoverOpen(false);
          }
          let empSearchArray = [];
          const employees = Object.entries(response.data).map(([id, name]) => ({
            EmployeeId: id,
            EmployeeName: name
          }));
          for (let search of employees) {
            empSearchArray.push(search.EmployeeId);
          }
          setSearchResults(empSearchArray);
        }
      });
    } else {
      if (popoverOpen) {
        setPopoverOpen(false);
      }
      if (searchResults.length > 0) {
        setSearchResults([]);
      }
    }
  };

  return (
    <IonModal isOpen={isOpen} backdropDismiss={backdropDismiss}>
      <FilterModal
        isOpen={isFilterModalOpen}
        moduleName={filterModalModuleName}
        cancel={() => setIsFilterModalOpen(false)}
        confirm={onFilterModalConfirm}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {translations["lbl_mob_req_approval"] || "Requisition Approval"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText>
                <h3 className="ion-no-margin">&nbsp;{cardHeader}</h3>
              </IonText>
            </IonCol>
            <IonCol size="auto">
              <IonButton fill="clear" size="small" onClick={onSelectAllClick}>
                <IonIcon
                  slot="start"
                  icon={checkmarkCircleOutline}
                  size="small"
                />
                {translations["lbl_btn_selAll"] || "Select All"}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {requisition.map((card, index) => {
          const onCardSelect = (checked: boolean) => {
            onSelect(checked, card.LineNum);
          };
          return (
            <IonCard key={index}>
              <IonCardHeader>
                <IonCardTitle class="card-title">
                  <IonCheckbox
                    checked={card.checked}
                    onClick={() => {
                      onCardSelect(!card.checked);
                    }}
                  ></IonCheckbox>
                  &nbsp;&nbsp;{card.LineNum}
                </IonCardTitle>
                <IonCardSubtitle>{card.ItemDesc}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["in_req_line.quantity_requested"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.Qty}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields["in_req_line.unit_cost"]
                        ?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.UnitCost}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {
                      metadata?.fields[
                        "in_v_req_computed_columns.cf_extended_cost"
                      ]?.TranslatedIdText
                    }
                  </IonCol>
                  <IonCol>{card.ExtCost}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol></IonCol>
                  <IonCol size="auto">
                    <IonButton
                      fill="clear"
                      size="small"
                      onClick={() => {
                        setLineNum(card.LineNum);
                        setShowLogActivity(true);
                      }}
                    >
                      {translations["lbl_log_activity"] || "Log Activity"}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          );
        })}
        <IonGrid class="comment-title">
          <IonRow>
            <IonCol>
              <IonText>
                <h3 className="ion-no-margin">
                  {translations["lbl_incyclecountbatch_comment"] || "Comment"}
                </h3>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonCard>
          <IonTextarea
            class="approval-textarea"
            placeholder={translations["lbl_enter comments"] || "Enter Comments"}
            autoGrow={true}
            onIonChange={(ev) => {
              setComments(String(ev.detail.value));
            }}
          ></IonTextarea>
        </IonCard>
        {getAllFields(metadata).map((field) => {
          if (field.PropertyName === "NextReviewer") {
            if (field.ElementType === "Dropdown") {
              return (
                <React.Fragment>
                  <IonItem onClick={() => setShowModal(true)}>
                    <IonLabel>
                      {translations["lbl_inreqline_nextreviewer"] || "Next Reviewer"}
                    </IonLabel>
                    <IonLabel slot="end">{nextReviewer}</IonLabel>
                    <IonIcon icon={chevronDownOutline} slot="end" />
                  </IonItem>

                  <IonModal isOpen={showModal}>
                    <IonHeader>
                      <IonToolbar>
                        <IonTitle>Select Reviewer</IonTitle>
                        <IonButtons slot="end">
                          <IonButton onClick={handleClose}>Close</IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <IonItem>
                        <IonInput
                          placeholder="Filter Reviewers"
                          onIonChange={e => setFilter(e.detail.value!)}
                          value={filter}
                        ></IonInput>
                        <IonIcon
                          slot="end"
                          ios={filterCircleOutline}
                          md={filterCircleSharp}
                        ></IonIcon>
                      </IonItem>
                      <IonRadioGroup value={selectedReviewer} onIonChange={handleRadioChange}>
                        {filteredReviewerList.map((card, index) => (
                          <IonItem key={index}>
                            <IonLabel>{card.Code} - {card.Description}</IonLabel>
                            <IonRadio slot="start" value={card.Code} />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonContent>
                    <IonFooter>
                      <IonButton expand="block" onClick={handleOk}>OK</IonButton>
                      <IonButton fill="outline" expand="block" onClick={handleClose}>Cancel</IonButton>
                    </IonFooter>
                  </IonModal>
                </React.Fragment>
              );
            } else if (field.ElementType === "ISS") {
              return (
                <IonItem>
                  <IonLabel>
                    {translations["lbl_inreqline_nextreviewer"] || "Next Reviewer"}
                  </IonLabel>
                  <IonInput
                    class="ion-text-end"
                    value={nextReviewer}
                    onIonInput={(ev) => { 
                      onFilterModalConfirm(ev.target.value);
                      handleEmployeeSearch(ev);
                    }}
                  ></IonInput>
                  <IonIcon
                    slot="end"
                    ios={searchOutline}
                    md={searchSharp}
                    onClick={async () => {
                      resetSearchState();
                      setFilterModalColumnName('employee_id');
                      const response: ApiResponse = await getValueSearchDetails(
                        {
                          tableName: 'em_employee',
                          columnName: 'employee_id',
                        }
                      );
                      if (response.status === 200) {
                        setFilterModalModuleName(response.data.QueryWizardModuleName);
                        setIsFilterModalOpen(true);
                      }
                    }}
                  ></IonIcon>
                </IonItem>
              );
            }
          }
        })}
        <IonButton
          expand="block"
          disabled={!checkEmpty()}
          onClick={() => validateCheckedList(approveAction)}
        >
          {translations["lbl_approve"] || "Approve"}
        </IonButton>
        <IonButton
          fill="outline"
          disabled={!checkEmpty()}
          expand="block"
          onClick={() => validateCheckedList(reviewAction)}
        >
          {translations["lbl_review"] || "Review"}
        </IonButton>
        <IonButton
          fill="outline"
          disabled={!checkEmpty()}
          color="danger"
          expand="block"
          onClick={() => validateCheckedList(rejectAction)}
        >
          {translations["lbl_reject"] || "Reject"}
        </IonButton>
        <ReqApprovalLogActivity
          isOpen={showLogActivity}
          dismiss={() => setShowLogActivity(false)}
          setShowLoading={setShowLoading}
          lineNum={lineNum}
          requisition={requisition}
        />
      </IonContent>
      <ISSPopover
        popover={popover}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setElementValue={setNextReviewer}
      />
    </IonModal> 
  );
};

export default ReqApprovalModal;
