import {
  IonPage,
  IonToolbar,
  IonButtons,
  IonContent,
  IonButton,
  IonFooter,
  IonLoading,
  IonList,
  IonItem,
  IonLabel,
  IonSelect,
  IonInput,
  IonSelectOption,
  IonNote,
  useIonAlert,
  IonToggle,
  IonTextarea,
  IonIcon,
  IonListHeader,
  IonFab,
  isPlatform,
  IonFabButton,
  IonFabList,
  IonModal,
  IonHeader,
  IonTitle,
  IonRadioGroup,
  IonRadio
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import { getUserHasAccess } from "../util/UserHelper";
import "./CreateQuickWO.css";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { getColumnsWithGroup } from "../api/Windows";
import {
  createViaCopy,
  getCustomColumnsWithGroup,
  getTemplateForWindow,
  GetTemplateForWindowParams,
  getTemplateForWorkOrder,
  GetTemplateForWorkOrderParams,
  getWorkOrder,
} from "../api/WorkOrders";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import { convertBlobToBase64, readDataAsBase64 } from "../util/FileHelper";
import { camera, cloudUpload, push, trashOutline } from "ionicons/icons";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { ApiResponse } from "../models/api/ApiResponse";
import { addFollowup } from "../api/Events";
import { getEquipment } from "../api/EquipmentMasters";
import { findEmployee } from "../api/Employees";
import { chevronDownOutline, searchOutline, searchSharp, filterCircleSharp, filterCircleOutline } from "ionicons/icons";
import { getValueSearchDetails } from "../api/QueryDesigner";
import FilterModal from "../components/FilterModal";
import React from "react";
import ISSPopover from "../components/ISSPopover";

interface ValidValues {
  Code: string;
  Description: string;
}

const CreateQuickWO: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { pathname } = useLocation();
  const { equipId, workOrderId } = useParams<{
    equipId: string;
    workOrderId?: string;
  }>(); // TODO: Set up possibility of other param names for other quick work order types
  const displayEquipId = decodeParam(equipId);
  const displayWoId = !!workOrderId ? decodeParam(workOrderId) : undefined;
  const [area, setArea] = useState<string>();
  const [department, setDepartment] = useState<string>();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [showLoading, setShowLoading] = useState(false);
  const [metadata, setMetadata] = useState<any[]>([]);
  const [values, setValues] = useState<any>({});
  const [customValues, setCustomValues] = useState({});
  const [showError, setShowError] = useState<any>({});
  const [metadataError, setMetadataError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [emergencyAccess, setEmergencyAccess] = useState<boolean>(true);

  const [attachedDocuments, setAttachedDocuments] = useState<any[]>([]);
  const [propertyName, setPropertyName] = useState<string>("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [filterModalColumnName, setFilterModalColumnName] = useState("");
  const [filterModalModuleName, setFilterModalModuleName] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [assignedTo, setAssignedTo] = useState<string>("");
  const [nextReviewer, setNextReviewer] = useState<string>("");
  const [requestor, setRequestor] = useState<string>("");
  const [showNextReviewerModal, setShowNextReviewerModal] = useState(false);
  const [showAssignedToModal, setShowAssignedToModal] = useState(false);
  const [showRequestorModal, setShowRequestorModal] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState<string>('');
  const [selectedAssignto, setSelectedAssignTo] = useState<string>('');
  const [selectedRequestor, setSelectedRequestor] = useState<string>('');
  const [filter, setFilter] = useState('');
  const [searchValue, setSearchValue] = useState("");
  let [assignedToValidData, setAssignedToValidData] = useState<ValidValues[]>([]);
  let [reviewerValidData, setReviewerValidData] = useState<ValidValues[]>([]);
  let [requestorValidData, setRequestorValidData] = useState<ValidValues[]>([]);
  let [filteredReviewerList, setFilteredReviewerList] = useState<ValidValues[]>([]);
  let [filteredAssignToList, setFilteredAssignToList] = useState<ValidValues[]>([]);
  let [filteredRequestorList, setFilteredRequestorList] = useState<ValidValues[]>([]);
  const [technicians, setTechnicians] = useState<string[]>(Array(6).fill(""));
  const [showTechnicianModals, setShowTechnicianModals] = useState<boolean[]>(Array(6).fill(false));
  const [selectedTechnicians, setSelectedTechnicians] = useState<string[]>(Array(6).fill(''));
  let [technicianValidData, setTechnicianValidData] = useState<ValidValues[]>([]);
  let [filteredTechnicianLists, setFilteredTechnicianLists] = useState<ValidValues[][]>(Array(6).fill([]));

  // Set a number of things that depend on location
  // Using If block to set up for other types of quick work orders later, such as serial
  let subtitle = "";
  let routeBack = `/Equipment/${encodeParam(displayEquipId)}`;
  let routeForward = "";
  let templateWindowName = "";
  if (pathname.includes("requester")) {
    subtitle = "Create Request"; // TODO: Get translation for this
    routeForward = "/requester/workorder";
    templateWindowName = "wr";
  } else {
    if (workOrderId && displayWoId) {
      routeBack = `/workorder/${encodeParam(displayWoId)}`;
    }
    subtitle =
      translations["lbl_btn_create_quick_wo"] || "Create Quick Work Order";
    routeForward = "/workorder";
    templateWindowName = "quick";
  }

  const createRequestData = async () => {
    const data = {
      IncludeValidValues: true,
      IncludeValidValuesExceptions: [],
      IsReadOnly: true,
      ValidValueFilters: [],
      WindowName: pathname.includes("requester")
        ? "mob_requester"
        : "mob_wo_nb_quick",
      ContextPKey: {
        Plant: await getCurrentPlant(),
        WoBase: "",
        WoTask: "  ",
        WoSubtask: "  ",
      },
    };
    return data;
  };

  const getTemplate = async () => {
    if (workOrderId) {
      let templateData = {
        equipment: displayEquipId,
        woNumber: displayWoId,
        woType: "Routine",
        copyValue: "true",
      } as GetTemplateForWorkOrderParams;
      return getTemplateForWorkOrder(templateData);
    } else {
      let templateData = {
        equipment: displayEquipId,
        windowName: templateWindowName,
        woType: "Routine",
      } as GetTemplateForWindowParams;
      return getTemplateForWindow(templateData);
    }
  };

  const getAreaDepartment = async () => {
    getEquipment({ equipment: displayEquipId }).then((response) => {
      setArea(response.data.Area);
      setDepartment(response.data.Department);
    });
  };

  const attachFollowUp = async (response: ApiResponse) => {
    const woResponse = await getWorkOrder({
      woNumber: workOrderId!,
      events: true,
    });
    const followUpData = {
      baseWo: woResponse.data?.Events?.WoBase,
      followUpDateCreated: woResponse.data?.Events?.FollowUpOfDate,
      followUpTimeCreated: woResponse.data?.Events?.FollowUpOfTime,
      followupWo: response.data?.WorkOrderNumber,
      isBaseWorkOrder: true,
      isFollowUpWo: true,
    };
    addFollowup(followUpData);
  };

  const getWorkOrderTypeAccess = async () => {
    var emergencyAccess = await getUserHasAccess("w_wo_book_eu_main", "Work Order Tab Emergency New");
    setEmergencyAccess(emergencyAccess);
  }

  useEffect(() => {
    afterFilterData();
  }, [filter])

  useEffect(() => {
    values['AssignedTo'] = assignedTo;
    values['NextReviewer'] = nextReviewer;
    values['Requestor'] = requestor;
    technicians.forEach((technician, index) => {
      values[`Technician${index + 1}`] = technician;
    });
  }, [assignedTo, nextReviewer, requestor, technicians])

  useEffect(() => {
    setShowLoading(true);
    setMetadata([]);
    setValues({});
    setShowError({});
    setMetadataError(false);
    setTemplateError(false);
    setAttachedDocuments([]);
    getAreaDepartment();
    getWorkOrderTypeAccess().then(() => {
      if (!emergencyAccess) {
        setValue("WoType", "Routine");
      }
    });

    getTemplate().then((templateResponse) => {
      let templateWorkOrder = undefined as undefined | any;
      if (templateResponse.status !== 200) {
        setTemplateError(true);
      } else if (templateResponse.data) {
        templateWorkOrder = templateResponse.data.WorkOrder;
      }

      createRequestData().then((data) => {
        getColumnsWithGroup(data).then((response) => {
          if (response.status !== 200) {
            setMetadataError(true);
          } else if (response.data) {
            // Set metadata and values
            let fields = [] as any[];
            let logValues = {} as any;
            let errorVals = {} as any;
            let tempAssignedToValidData: ValidValues[] = [];
            let tempReviewerValidData: ValidValues[] = [];
            let tempRequestorValidData: ValidValues[] = [];
            let tempTechnicianValidData = Array(6).fill([]);

            const keys = Object.keys(response.data.fields);
            keys.forEach((key) => {
              fields.push(response.data.fields[key]);
              let currField = response.data.fields[key];
              let propName = currField.PropertyName;
              let elementType = currField.ElementType;
              if (propName === 'AssignedTo' && elementType === "Dropdown") {
                tempAssignedToValidData = currField.ValidValues;
              }
              if (propName === 'NextReviewer' && elementType === "Dropdown") {
                tempReviewerValidData = currField.ValidValues;
              }
              if (propName === 'Requestor' && elementType === "Dropdown") {
                tempRequestorValidData = currField.ValidValues;
              }
              if (/^Technician\d+$/.test(propName) && elementType === "Dropdown") {
                const match = propName.match(/^Technician(\d+)$/);
                const index = match ? parseInt(match[1], 10) - 1 : -1;
                if (index >= 0) {
                  tempTechnicianValidData[index] = currField.ValidValues;
                }
              }
              if (
                !!templateWorkOrder &&
                Object.hasOwn(templateWorkOrder, propName)
              ) {
                // If we have the template and it has the relevant property, use the template's property value to set the default value
                logValues[propName] = templateWorkOrder[propName];
              } else {
                if (propName === "WoType") {
                  logValues[propName] = "Routine";
                } else if (response.data.fields[key].NetType === "Boolean") {
                  logValues[propName] = false;
                } else {
                  logValues[propName] = "";
                }
              }

              if (response.data.fields[key].IsRequired) {
                errorVals[propName] = false;
              }
            });
            setAssignedToValidData(tempAssignedToValidData);
            setReviewerValidData(tempReviewerValidData);
            setRequestorValidData(tempRequestorValidData);
            setFilteredAssignToList(tempAssignedToValidData);
            setFilteredReviewerList(tempReviewerValidData);
            setFilteredRequestorList(tempRequestorValidData);
            setFilteredTechnicianLists(tempTechnicianValidData);
            
            fields.sort((a, b) => {
              return Number(a.FieldOrder) - Number(b.FieldOrder);
            });

            getCustomColumnsWithGroup(
              pathname.includes("requester")
                ? "mob_requester"
                : "mob_wo_nb_quick"
            ).then((response) => {
              let customFields = [] as string[];
              const keys = Object.keys(response.data.fields);
              keys.forEach((key) => {
                customFields.push(response.data.fields[key]);
              });
              let combinedFields = fields.concat(customFields);
              setMetadata(combinedFields);
            });
            setValues(logValues);
            setShowError(errorVals);
          }
          setShowLoading(false);
        });
      });
    });
  }, [displayEquipId]);

  const setValue = (propertyName: string, newVal: any) => {
    let temp = { ...values };
    temp[propertyName] = newVal;
    setValues(temp);
  };

  const handleCustomValues = (field: string, value: string) => {
    let temp = JSON.parse(JSON.stringify(customValues));
    temp[field] = value;
    setCustomValues(temp);
  };

  const enteriesInvalid = () => {
    let requiredEntries = [] as string[];
    metadata.forEach((meta) => {
      if (meta.IsRequired) {
        requiredEntries.push(meta.PropertyName);
      }
    });
    if (requiredEntries.length === 0) {
      return false;
    } else {
      let requiredAbsent = !requiredEntries.every((req) => {
        return (
          values[req] !== null &&
          values[req] !== undefined &&
          values[req] !== ""
        );
      });
      return requiredAbsent;
    }
  };

  async function submitWorkOrder() {
    let temp = { ...values };
    const keys = Object.keys(temp);
    keys.forEach((key) => {
      if (temp[key] === "") {
        temp[key] = null;
      }
    });
    temp.Area = area;
    temp.Department = department;
    temp.CopyFromAppId = "Mobile";
    temp.Equipment = displayEquipId;
    if (workOrderId && displayWoId) {
      temp.FollowUpToWoNumber = displayWoId;
    }
    if (attachedDocuments.length > 0) {
      temp.Documents = attachedDocuments;
    }
    if (customValues) {
      temp.UserDefinedValues = customValues;
    }
    if (templateWindowName === 'wr') {
      temp.IsWorkRequester = true;
    }
    temp.NotebookTranslations = {
      "wo_work_order.description": [
        {
          TableName: "wo_work_order",
          ElementName: "description",
          Language: "Default",
          PlainText: temp.Description,
          RichText: null,
        },
      ],
    };
    sendCreateViaCopy(temp);
  }

  const sendCreateViaCopy = async (
    requestObject: any,
    messageConfirmations?: string[]
  ) => {
    setShowLoading(true);
    const confirmationObject = {
      RuleConfirmationDetailsList: messageConfirmations?.map((messageId) => {
        return {
          MessageId: messageId,
          MessageConfirmationValue: {
            val1: "Y",
          },
        };
      }),
    };
    const data = {
      Confirmation: !!messageConfirmations ? confirmationObject : {},
      RequestObject: requestObject,
    };
    const response = await createViaCopy(data);
    if (response.status === 200 && response.data.Success) {
      // workOrderId is true if coming from completion screen and has follow up flag enabled
      !!workOrderId && attachFollowUp(response);
      presentAlert({
        header: `${translations["lbl_success"] || "Success"}!`,
        message: response.data.MessageList[0].Text,
        onDidDismiss: () => {
          setShowLoading(true);
          setMetadata([]);
          setValues({});
          setShowError({});
          setAttachedDocuments([]);
          setMetadataError(false);
          history.push(
            `${routeForward}/${encodeParam(response.data.WorkOrderNumber)}`,
            { prevUrl: window.location.href }
          );
        },
        buttons: ["OK"],
      });
    } else {
      handleApiFailResponse(response, requestObject);
    }
    setShowLoading(false);
  };

  const handleApiFailResponse = async (
    response: ApiResponse,
    requestObject: any
  ) => {
    // Display failure modal and stay on the page
    let errors = [] as string[];
    response.data?.MessageList?.forEach((message: any) => {
      errors.push(message.Text);
    });
    let message = "";
    if (errors.length > 0) {
      message = errors.join("\n");
    }
    let buttons: { text: string; handler?: () => void }[] = [
      {
        text: "OK",
      },
    ];

    const messageIds = response.data?.MessageList?.map((message: any) => {
      return message.Id;
    });
    // Handling message id
    if (messageIds.includes("WOWarranty")) {
      buttons = [
        {
          text: "No",
        },
        {
          text: "Yes",
          handler: () => {
            sendCreateViaCopy(requestObject, ["WOWarranty"]);
          },
        },
      ];
    }

    let requestWorkOrder = pathname.includes("requester")
      ? "Request"
      : "Work Order";
    presentAlert({
      header:
        translatedMessages["WOCreateFailed"]?.MessageText.replace(
          "Work Order",
          requestWorkOrder
        ) || `${requestWorkOrder} creation failed`,
      message: message,
      buttons: buttons,
    });
  };

  const selectFile = async () => {
    // invoke the hidden input's click event
    (document as any).getElementById("filePickerWO").click();
  };

  const fileClicked = async () => {
    (document as any).getElementById("filePickerWO").value = null;
  };

  const takePhoto = async () => {
    const source = await presentCustomPhotoSourceDialog();
    if (!source)
      return;
    await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      allowEditing: false,
      quality: 100,
      source: source as CameraSource,
    }).then(async (photo) => {
      presentAlert({
        message:
          translations["lbl_enter_file_name"] || "Please enter file name",
        backdropDismiss: false,
        buttons: [
          {
            text: translations["lbl_btn_ok"] || "OK",
            async handler(value) {
              if (!!value && value[0] !== "") {
                setShowLoading(true);
                let newPhoto = {
                  FileName: `${value[0]}.${photo.format}`,
                  DocContentBase64: "",
                };
                let base64Data = await readDataAsBase64(photo);
                if (isPlatform("hybrid")) {
                  newPhoto.DocContentBase64 = base64Data;
                } else {
                  newPhoto.DocContentBase64 = base64Data.split(";base64,")[1];
                }
                let allFiles = attachedDocuments.slice();
                allFiles.push(newPhoto);
                setAttachedDocuments(allFiles);
                setShowLoading(false);
                return;
              }
              return false;
            },
          },
          {
            text: translations["lbl_btn_cancel"] || "Cancel",
            role: "Cancel",
          },
        ],
        inputs: [
          {
            placeholder: translations["lbl_file_name"] || "File Name",
            attributes: {
              maxlength: 100,
            },
          },
        ],
      });
    });
  };

  const presentCustomPhotoSourceDialog = async () => {
    const promise = new Promise<CameraSource | null>((resolve) => {
      presentAlert({
        header: 'Choose Photo Source',
        message: 'Do you want to take a new photo or select an existing one?',
        buttons: [
          {
            text: 'Take Picture',
            handler: () => {
              console.log('Take Picture clicked');
              resolve(CameraSource.Camera);
            },
          },
          {
            text: 'From Photos',
            handler: () => {
              console.log('From Photos clicked');
              resolve(CameraSource.Photos);
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
              resolve(null);
            },
          },
        ],
      });
    });

    return promise;
  };

  const fileSelected = async (event: any) => {
    let newDoc = {
      FileName: "",
      DocContentBase64: "",
    };
    let uploadedFile = event.target.files[0];
    presentAlert({
      message: translations["lbl_enter_file_name"] || "Please enter file name",
      backdropDismiss: false,
      buttons: [
        {
          text: translations["lbl_btn_ok"] || "OK",
          async handler(value) {
            if (!!value && value[0] !== "") {
              setShowLoading(true);
              newDoc.FileName = uploadedFile.name;
              let base64Data = (await convertBlobToBase64(
                uploadedFile
              )) as string;
              let base64Array = base64Data.split(";base64,");
              newDoc.DocContentBase64 = base64Array[1];
              let allFiles = attachedDocuments.slice();
              allFiles.push(newDoc);
              setAttachedDocuments(allFiles);
              event.target.value = "";
              setShowLoading(false);
              return;
            }
            return false;
          },
        },
        {
          text: translations["lbl_btn_cancel"] || "Cancel",
          role: "Cancel",
        },
      ],
      inputs: [
        {
          id: "txtFileName",
          placeholder: translations["lbl_file_name"] || "File Name",
          attributes: {
            maxlength: 100,
          },
          value: uploadedFile.name,
        },
      ],
      onDidPresent(event) {
        //pre-select the name so that its easier for the user to edit
        (document.getElementById("txtFileName") as HTMLInputElement).select();
      },
    });
  };

  const removeFile = (fileIndex: number) => {
    let docsCopy = attachedDocuments.slice();
    docsCopy.splice(fileIndex, 1);
    setAttachedDocuments(docsCopy);
  };

  const handleEmployeeSearch = (e: CustomEvent, cardPropertyName: string) => {
    let newVal = (e.target as HTMLInputElement)?.value;
    setPropertyName(cardPropertyName);
    if (cardPropertyName === "NextReviewer") {
      setNextReviewer(newVal);
    }
    else if (cardPropertyName === "Requestor") {
      setRequestor(newVal);
    }
    else {
      setAssignedTo(newVal);
    }
    const technicianMatch = cardPropertyName.match(/^Technician(\d+)$/);
    if (technicianMatch) {
      const index = parseInt(technicianMatch[1], 10) - 1;
      const updatedTechnicians = [...technicians];
      updatedTechnicians[index] = newVal;
      setTechnicians(updatedTechnicians);
    }
    if (newVal.length > 2) {
      findEmployee(newVal).then((response) => {
        if (response.status === 200) {
          if (Object.keys(response.data).length !== 0) {
            popover.current!.event = e;
            setPopoverOpen(true);
          } else if (popoverOpen) {
            setPopoverOpen(false);
          }
          let empSearchArray = [];
          const employees = Object.entries(response.data).map(([id, name]) => ({
            EmployeeId: id,
            EmployeeName: name
          }));
          for (let search of employees) {
            empSearchArray.push(search.EmployeeId);
          }
          setSearchResults(empSearchArray);
        }
      });
    } else {
      if (popoverOpen) {
        setPopoverOpen(false);
      }
      if (searchResults.length > 0) {
        setSearchResults([]);
      }
    }
  };

  const resetSearchState = () => {
    setFilterModalColumnName('');
    setFilterModalModuleName('');
  };

  const onFilterModalConfirm = (selectedItem: string | number | null | undefined, currentPropertyName: string | null | undefined = null) => {
    setParameter(filterModalColumnName, selectedItem, currentPropertyName);
    setIsFilterModalOpen(false);
    resetSearchState();
  };

  const setParameter = (
    paramKey: string,
    paramValue: string | number | null | undefined,
    currPropertyName: string | null
  ) => {
    const effectivePropertyName = currPropertyName || propertyName;
    if (effectivePropertyName === "NextReviewer") {
      setNextReviewer(paramValue as string);
    }
    else if (effectivePropertyName === "Requestor") {
      setRequestor(paramValue as string);
    }
    else if (effectivePropertyName === "AssignedTo") {
      setAssignedTo(paramValue as string);
    }
    const technicianMatch = effectivePropertyName.match(/^Technician(\d+)$/);
    if (technicianMatch) {
      const index = parseInt(technicianMatch[1], 10) - 1;
      const updatedTechnicians = [...technicians];
      updatedTechnicians[index] = paramValue as string;
      setTechnicians(updatedTechnicians);
    }
  };

  const handleClose = (e: any, card: any) => {
    if (card.PropertyName === 'NextReviewer') {
      setSelectedReviewer(selectedReviewer);
      setShowNextReviewerModal(false);
    }
    else if (card.PropertyName === 'Requestor') {
      setSelectedRequestor(selectedReviewer);
      setShowRequestorModal(false);
    }
    else if (card.PropertyName === 'AssignedTo') {
      setSelectedAssignTo(selectedAssignto);
      setShowAssignedToModal(false);
    }
    const technicianMatch = card.PropertyName.match(/^Technician(\d+)$/);
    if (technicianMatch) {
      const index = parseInt(technicianMatch[1], 10) - 1;
      const updatedSelectedTechnicians = [...selectedTechnicians];
      updatedSelectedTechnicians[index] = selectedTechnicians[index];
      setSelectedTechnicians(updatedSelectedTechnicians);

      const updatedShowTechnicianModals = [...showTechnicianModals];
      updatedShowTechnicianModals[index] = false;
      setShowTechnicianModals(updatedShowTechnicianModals);
    }
    setFilter('');
  };

  const handleRadioChange = (e: any, card: any) => {
    if (card.PropertyName === 'NextReviewer') {
      setSelectedReviewer(e.detail.value);
    }
    else if (card.PropertyName === 'Requestor') {
      setSelectedRequestor(e.detail.value);
    }
    else if (card.PropertyName === 'AssignedTo') {
      setSelectedAssignTo(e.detail.value);
    }
    const technicianMatch = card.PropertyName.match(/^Technician(\d+)$/);
    if (technicianMatch) {
      const index = parseInt(technicianMatch[1], 10) - 1;
      const updatedSelectedTechnicians = [...selectedTechnicians];
      updatedSelectedTechnicians[index] = e.detail.value;
      setSelectedTechnicians(updatedSelectedTechnicians);
    }
  };

  const handleOk = (e: any, card: any) => {
    if (card.PropertyName === 'NextReviewer') {
      setNextReviewer(selectedReviewer);
      setShowNextReviewerModal(false);
    } else if (card.PropertyName === 'Requestor') {
      setRequestor(selectedRequestor);
      setShowRequestorModal(false);
    } else if (card.PropertyName === 'AssignedTo') {
      setAssignedTo(selectedAssignto);
      setShowAssignedToModal(false);
    } else if (/^Technician\d+$/.test(card.PropertyName)) {
      const match = card.PropertyName.match(/^Technician(\d+)$/);
      const index = match ? parseInt(match[1], 10) - 1 : -1;
      if (index >= 0) {
        const updatedTechnicians = [...technicians];
        updatedTechnicians[index] = selectedTechnicians[index];
        setTechnicians(updatedTechnicians);
  
        const updatedShowTechnicianModals = [...showTechnicianModals];
        updatedShowTechnicianModals[index] = false;
        setShowTechnicianModals(updatedShowTechnicianModals);
      }
    }
    setFilter('');
  };

  const afterFilterData = () => {
    if (propertyName === "NextReviewer") {
      if (filter === "") {
        setFilteredReviewerList(reviewerValidData);
      }
      else {
        setFilteredReviewerList(reviewerValidData.filter(card =>
          card.Code.toLowerCase().includes(filter.toLowerCase()) || card.Description.toLowerCase().includes(filter.toLowerCase())))
      }
    }
    else if (propertyName === "Requestor") {
      if (filter === "") {
        setFilteredRequestorList(requestorValidData);
      }
      else {
        setFilteredRequestorList(requestorValidData.filter(card =>
          card.Code.toLowerCase().includes(filter.toLowerCase()) || card.Description.toLowerCase().includes(filter.toLowerCase())))
      }
    }
    else if(propertyName === "AssignedTo") {
      if (filter === "") {
        setFilteredAssignToList(assignedToValidData);
      }
      else {
        setFilteredAssignToList(assignedToValidData.filter(card =>
          card.Code.toLowerCase().includes(filter.toLowerCase()) || card.Description.toLowerCase().includes(filter.toLowerCase())))
      }
    }
    else if(/^Technician\d+$/.test(propertyName)) {
      const match = propertyName.match(/^Technician(\d+)$/);
      const index = match ? parseInt(match[1], 10) - 1 : -1;
      if (filter === "") {
        const updatedFilteredTechnicianLists = [...filteredTechnicianLists];
        updatedFilteredTechnicianLists[index] = technicianValidData;
        setFilteredTechnicianLists(updatedFilteredTechnicianLists);
      }
      else {
        const updatedFilteredTechnicianLists = [...filteredTechnicianLists];
        updatedFilteredTechnicianLists[index] = technicianValidData.filter(card =>
          card.Code.toLowerCase().includes(filter.toLowerCase()) || card.Description.toLowerCase().includes(filter.toLowerCase()));
        setFilteredTechnicianLists(updatedFilteredTechnicianLists);
      }
    }
  }

  const makeContent = () => {
    return (
      <IonList class="ion-padding">
        {metadata.map((meta: any, index: number) => {
          if (meta.PropertyName === "WoType" && !emergencyAccess) {
            return null;
          }
          let required = meta.IsRequired;
          const calculateInput = () => {
            if (meta.PropertyName === "WoType") {
              let checked = values[meta.PropertyName] === "Emergency";
              return (
                <IonToggle
                  slot="end"
                  checked={checked}
                  onIonChange={(ev) => {
                    if (ev.detail.checked) {
                      setValue(meta.PropertyName, "Emergency");
                    } else {
                      setValue(meta.PropertyName, "Routine");
                    }
                  }}
                ></IonToggle>
              );
            } else if ((meta.PropertyName === "NextReviewer" || meta.PropertyName === "AssignedTo" || meta.PropertyName === "Requestor" || /^Technician\d+$/.test(meta.PropertyName)) && meta.ElementType) {
              if (meta.ElementType === "Dropdown") {
                values['Requestor'] = null;
                return (
                  <React.Fragment>
                    <IonItem
                      class="dropdown-item"
                      onClick={() => {
                        setPropertyName(meta.PropertyName);
                        if (meta.PropertyName === "NextReviewer") {
                          setShowNextReviewerModal(true);
                        } else if (meta.PropertyName === "AssignedTo") {
                          setShowAssignedToModal(true);
                        } else if (meta.PropertyName === "Requestor") {
                          setShowRequestorModal(true);
                        } else {
                          const index = parseInt(meta.PropertyName.match(/^Technician(\d+)$/)[1], 10) - 1;
                          const updatedShowTechnicianModals = [...showTechnicianModals];
                          updatedShowTechnicianModals[index] = true;
                          setShowTechnicianModals(updatedShowTechnicianModals);
                        }
                      }}>
                      <IonLabel slot="start" className="align-value">{
                        meta.PropertyName === "NextReviewer" ? nextReviewer : meta.PropertyName === "AssignedTo" ? assignedTo : meta.PropertyName === "Requestor" ? requestor : technicians[parseInt(meta.PropertyName.match(/^Technician(\d+)$/)[1], 10) - 1] }                     </IonLabel>
                      <IonIcon icon={chevronDownOutline} className="dropdown-icon" slot="end" />
                    </IonItem>
                    <IonModal isOpen={meta.PropertyName === "NextReviewer" ? showNextReviewerModal : meta.PropertyName === "AssignedTo" ? showAssignedToModal : meta.PropertyName === "Requestor" ? showRequestorModal : showTechnicianModals[parseInt(meta.PropertyName.match(/^Technician(\d+)$/)[1], 10) - 1]}>
                      <IonHeader>
                        <IonToolbar>
                          <IonTitle>{meta.PropertyName === "NextReviewer" ? "Select Reviewer" : meta.PropertyName === "AssignedTo" ? "Select Assigned To" : meta.PropertyName === "Requestor" ? "Select Requestor" : "Select Technician"}</IonTitle>
                          <IonButtons slot="end">
                            <IonButton onClick={e => handleClose(e, meta)}>Close</IonButton>
                          </IonButtons>
                        </IonToolbar>
                      </IonHeader>
                      <IonContent>
                        <IonItem>
                          <IonInput
                            placeholder={meta.PropertyName === "NextReviewer" ? "Filter Reviewers" : meta.PropertyName === "AssignedTo" ? "Filter Assigned To" : meta.PropertyName === "Requestor" ? "Filter Requestor" : "Filter Technician"}                            onIonChange={e => setFilter(e.detail.value!)}
                            value={filter}
                          ></IonInput>
                          <IonIcon
                            slot="end"
                            ios={filterCircleOutline}
                            md={filterCircleSharp}
                          ></IonIcon>
                        </IonItem>
                        <IonRadioGroup value={meta.PropertyName === "NextReviewer" ? selectedReviewer : meta.PropertyName === "AssignedTo" ? selectedAssignto : meta.PropertyName === "Requestor" ? selectedRequestor : selectedTechnicians[parseInt(meta.PropertyName.match(/^Technician(\d+)$/)[1], 10) - 1]} onIonChange={e => handleRadioChange(e, meta)}>
                        {meta.PropertyName === "NextReviewer" ?
                          filteredReviewerList.map((meta, index) => (
                            <IonItem key={index}>
                              <IonLabel>{meta.Code} - {meta.Description}</IonLabel>
                              <IonRadio slot="start" value={meta.Code} />
                            </IonItem>
                          ))
                          : meta.PropertyName === "AssignedTo" ?
                            filteredAssignToList.map((meta, index) => (
                              <IonItem key={index}>
                                <IonLabel>{meta.Code} - {meta.Description}</IonLabel>
                                <IonRadio slot="start" value={meta.Code} />
                              </IonItem>
                            ))
                            : meta.PropertyName === "Requestor" ?
                              filteredRequestorList.map((meta, index) => (
                                <IonItem key={index}>
                                  <IonLabel>{meta.Code} - {meta.Description}</IonLabel>
                                  <IonRadio slot="start" value={meta.Code} />
                                </IonItem>
                              ))
                              :
                              filteredTechnicianLists[parseInt(meta.PropertyName.match(/^Technician(\d+)$/)[1], 10) - 1].map((meta, index) => (
                                <IonItem key={index}>
                                  <IonLabel>{meta.Code} - {meta.Description}</IonLabel>
                                  <IonRadio slot="start" value={meta.Code} />
                                </IonItem>
                              ))
                          }
                        </IonRadioGroup>
                      </IonContent>
                      <IonFooter>
                        <IonButton expand="block" onClick={e => handleOk(e, meta)}>OK</IonButton>
                        <IonButton fill="outline" expand="block" onClick={e => handleClose(e, meta)}>Cancel</IonButton>
                      </IonFooter>
                    </IonModal>
                  </React.Fragment>
                );
              }
              else if (meta.ElementType === "ISS") {
                return (
                  <IonItem class="wo-editable-container">
                    <IonInput
                      class="wo-editable-input-assigned"
                      value={meta.PropertyName === "NextReviewer" ? nextReviewer : meta.PropertyName === "AssignedTo" ? assignedTo : meta.PropertyName === "Requestor" ? requestor : technicians[parseInt(meta.PropertyName.match(/^Technician(\d+)$/)[1], 10) - 1]}
                      onIonInput={(ev) => {
                        onFilterModalConfirm(ev.target.value, meta.PropertyName);
                        handleEmployeeSearch(ev, meta.PropertyName);
                      }}
                    ></IonInput>
                    <IonIcon
                      class="search-icon"
                      slot="end"
                      ios={searchOutline}
                      md={searchSharp}
                      onClick={async () => {
                        resetSearchState();
                        setFilterModalColumnName('employee_id');
                        const response: ApiResponse = await getValueSearchDetails(
                          {
                            tableName: 'em_employee',
                            columnName: 'employee_id',
                          }
                        );
                        if (response.status === 200) {
                          setFilterModalModuleName(response.data.QueryWizardModuleName);
                          setPropertyName(meta.PropertyName);
                          setIsFilterModalOpen(true);
                        }
                      }}
                    ></IonIcon>
                  </IonItem>
                );
              }
            } else if (meta.ValidValues && meta.ValidValues.length > 0) {
              let currVal = values[meta.PropertyName];
              let selectVal = meta.ValidValues.find((validVal: any) => {
                return validVal.Code === currVal;
              });
              return (
                <IonSelect
                  class="wo-editable-input"
                  selectedText={!!selectVal ? selectVal.Description : ""}
                  interface="popover"
                  onIonChange={(ev: any) => {
                    if (required) {
                      let temp = { ...showError };
                      if (
                        !ev.detail.value ||
                        ev.detail.value === "" ||
                        ev.detail.value.length === 0
                      ) {
                        temp[meta.PropertyName] = true;
                        setShowError(temp);
                      } else if (
                        ev.detail.value.length > 0 &&
                        showError[meta.PropertyName]
                      ) {
                        temp[meta.PropertyName] = false;
                        setShowError(temp);
                      }
                    }
                    meta.PropertyName === "StringValue" ||
                      meta.PropertyName === "NumericValue" ||
                      meta.PropertyName === "DateValue"
                      ? handleCustomValues(meta.IdText, ev.detail.value)
                      : setValue(meta.PropertyName, ev.detail.value);
                  }}
                  onIonCancel={(ev: any) => {
                    let temp = { ...showError };
                    if (
                      !values[meta.PropertyName] ||
                      values[meta.PropertyName] === "" ||
                      values[meta.PropertyName].length === 0
                    ) {
                      temp[meta.PropertyName] = true;
                      setShowError(temp);
                    } else if (
                      values[meta.PropertyName].length > 0 &&
                      showError[meta.PropertyName]
                    ) {
                      temp[meta.PropertyName] = false;
                      setShowError(temp);
                    }
                  }}
                >
                  {!required && (
                    <IonSelectOption
                      key={`${meta.PropertyName}-empty-select-option`}
                      value={null}
                    >
                      {" "}
                    </IonSelectOption>
                  )}
                  {meta.ValidValues.map((value: any, index: number) => {
                    return (
                      <IonSelectOption
                        value={value.Code}
                        key={`${meta.PropertyName}-select-option-${index}`}
                      >
                        {value.Description}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              );
            } else if (meta.NetType === "Boolean") {
              return (
                <IonToggle
                  slot="end"
                  checked={values[meta.PropertyName]}
                  onIonChange={(ev) => {
                    setValue(meta.PropertyName, ev.detail.checked);
                  }}
                ></IonToggle>
              );
            } else if (meta.ElementType === "htmlEditor") {
              return (
                <IonTextarea
                  class="wo-editable-input"
                  autoGrow
                  required={required}
                  value={values[meta.PropertyName]}
                  onIonChange={(ev: any) => {
                    if (required) {
                      let temp = { ...showError };
                      if (ev.detail.value.length === 0) {
                        temp[meta.PropertyName] = true;
                        setShowError(temp);
                      } else if (
                        ev.detail.value.length > 0 &&
                        showError[meta.PropertyName]
                      ) {
                        temp[meta.PropertyName] = false;
                        setShowError(temp);
                      }
                    }
                    setValue(meta.PropertyName, ev.detail.value);
                  }}
                ></IonTextarea>
              );
            } else {
              return (
                <IonInput
                  required={required}
                  class="wo-editable-input"
                  type={meta.NetType === "DateTime" ? "date" : undefined}
                  onIonInput={(ev: any) => {
                    if (required) {
                      let temp = { ...showError };
                      if (ev.detail.target.value.length === 0) {
                        temp[meta.PropertyName] = true;
                        setShowError(temp);
                      } else if (
                        ev.detail.target.value.length > 0 &&
                        showError[meta.PropertyName]
                      ) {
                        temp[meta.PropertyName] = false;
                        setShowError(temp);
                      }
                    }
                    meta.PropertyName === "StringValue" ||
                      meta.PropertyName === "NumericValue" ||
                      meta.PropertyName === "DateValue"
                      ? handleCustomValues(meta.IdText, ev.detail.target.value)
                      : setValue(meta.PropertyName, ev.detail.target.value);
                  }}
                ></IonInput>
              );
            }
          };
          return (
            <IonItem
              lines="none"
              class="wo-list-item"
              key={`${meta.PropertyName}-${index}`}
            >
              <IonLabel
                className="wo-editable-input-label"
                position={
                  meta.NetType === "Boolean" || meta.PropertyName === "WoType"
                    ? undefined
                    : "stacked"
                }
                color={required ? "danger" : undefined}
              >
                {meta.PropertyName === "WoType"
                  ? translations["lbl_create Emergency"] ||
                  "Create as Emergency"
                  : meta.TranslatedIdText}
                {required && "*"}
              </IonLabel>
              {calculateInput()}
              {required && (
                <IonNote
                  className="quick-wo-error-note"
                  hidden={!showError[meta.PropertyName]}
                  slot="error"
                  color="danger"
                  key={`${meta.PropertyName}-error`}
                >
                  {translatedMessages["errIsRequired"]?.MessageText.replace(
                    "@1",
                    meta.TranslatedIdText
                  )}
                </IonNote>
              )}
            </IonItem>
          );
        })}
        <ISSPopover
          popover={popover}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setElementValue={propertyName === "NextReviewer" ? setNextReviewer : propertyName === "AssignedTo" ? setAssignedTo : propertyName === "Requestor" ? setRequestor : (value) => {
            const match = propertyName.match(/^Technician(\d+)$/);
            const index = match ? parseInt(match[1], 10) - 1 : -1;
            const updatedTechnicians = [...technicians];
            updatedTechnicians[index] = value;
            setTechnicians(updatedTechnicians);
          }}
        />
      </IonList>
    );
  };

  return (
    <IonPage>
      <PageHeader
        title={`${translations["lbl_category_equipment"] || "Equipment"
          } ${displayEquipId}`}
        subtitle={subtitle}
        showBack
      />
      <FilterModal
        isOpen={isFilterModalOpen}
        moduleName={filterModalModuleName}
        cancel={() => setIsFilterModalOpen(false)}
        confirm={onFilterModalConfirm}
      />
      <IonContent fullscreen>
        {metadataError && (
          <IonLabel color="danger">
            {translatedMessages["ErrRetrievingFields"]?.MessageText ||
              "Error retrieving the fields for the creation form"}
          </IonLabel>
        )}
        {templateError && (
          <IonLabel color="danger">
            Error retrieving the default values for the creation form
          </IonLabel>
        )}
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          duration={5000}
        />
        {makeContent()}
        <div className="ion-padding">
          <input
            hidden
            type="file"
            id="filePickerWO"
            onChange={fileSelected}
            onClick={fileClicked}
          />
          <IonList>
            <IonListHeader key="attached-docs-list-header">
              <IonLabel>Attach Documents</IonLabel>
            </IonListHeader>
            {attachedDocuments.length === 0 ? (
              <IonItem lines="none" key="no-attached-documents">
                <IonLabel class="ion-text-wrap">
                  {translatedMessages["NoAttachedDocuments"]?.MessageText ||
                    "No Attached Documents. Use the upload button to add files and pictures."}
                </IonLabel>
              </IonItem>
            ) : (
              attachedDocuments.map((doc, index) => {
                return (
                  <IonItem lines="none" key={`${doc.FileName}-${index}`}>
                    <IonLabel>{doc.FileName}</IonLabel>
                    <IonButton slot="end" onClick={() => removeFile(index)}>
                      <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
                    </IonButton>
                  </IonItem>
                );
              })
            )}
          </IonList>
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="center" edge>
          {isPlatform("ios") ? (
            <IonFabButton color="primary" onClick={() => selectFile()}>
              <IonIcon icon={cloudUpload}></IonIcon>
            </IonFabButton>
          ) : (
            <>
              <IonFabButton color="primary">
                <IonIcon icon={push}></IonIcon>
              </IonFabButton>
              <IonFabList side="top">
                <IonFabButton color="tertiary" onClick={() => takePhoto()}>
                  <IonIcon icon={camera}></IonIcon>
                </IonFabButton>
                <IonFabButton
                  color="tertiary"
                  onClick={() => {
                    selectFile();
                  }}
                >
                  <IonIcon icon={cloudUpload}></IonIcon>
                </IonFabButton>
              </IonFabList>
            </>
          )}
        </IonFab>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              fill="solid"
              routerLink={routeBack}
              routerDirection="back"
            >
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              fill="solid"
              disabled={enteriesInvalid()}
              onClick={() => submitWorkOrder()}
            >
              {translations["lbl_submit"] || "Submit"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default CreateQuickWO;