import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonFooter
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { TranslationsContext } from "../util/Translations";
import "./WOApprovalModal.css";
import {
  WorkOrder,
  WorkOrderActivity,
  WorkOrderMetadata,
  formatData,
  getAllFields,
} from "../models/workorders/WorkOrder";
import { chevronDownOutline, filterCircleOutline, filterCircleSharp, searchOutline, searchSharp, timerOutline } from "ionicons/icons";
import WOApprovalLogActivity from "./WOApprovalLogActivity";
import {
  ProcessWorkOrderActionListData,
  changeStatus,
} from "../api/MobileApproval";
import React from "react";
import { ApiResponse } from "../models/api/ApiResponse";
import { getValueSearchDetails } from "../api/QueryDesigner";
import FilterModal from "./FilterModal";
import { findEmployee } from "../api/Employees";
import ISSPopover from "./ISSPopover";

interface ApprovalModalProps {
  isOpen: boolean;
  cancel: () => void;
  approve: (status: string) => void;
  review: (status: string) => void;
  reject: (status: string) => void;
  cardHeader: string;
  metadata: WorkOrderMetadata;
  workOrder: WorkOrder;
  woActivity: WorkOrderActivity[];
  description: string;
  longDescription: string;
  nextReviewer: string;
  setNextReviewer: (nextReviewer: string) => void;
  assignedTo: string;
  setAssignedTo: (assignedTo: string) => void;
  setShowLoading: (showLoading: boolean) => void;
  backdropDismiss: boolean;
}

interface ValidValues {
  Code: string;
  Description: string;
}

const WOApprovalModal: React.FC<ApprovalModalProps> = (props) => {
  const {
    isOpen,
    cancel,
    approve,
    review,
    reject,
    cardHeader,
    metadata,
    workOrder,
    woActivity,
    description,
    longDescription,
    nextReviewer,
    setNextReviewer,
    assignedTo,
    setAssignedTo,
    setShowLoading,
    backdropDismiss
  } = props;
  const { translations } = useContext(TranslationsContext);
  const [comments, setComments] = useState<string>("");
  const [showLogActivity, setShowLogActivity] = useState(false);
  const approveAction = "approve";
  const reviewAction = "review";
  const rejectAction = "reject";
  const [filter, setFilter] = useState('');
  const [reviewerValidData, setReviewValidData] = useState<ValidValues[]>([]);
  const [assignToValidData, setAssignedToValidData] = useState<ValidValues[]>([]);
  const [selectedReviewer, setSelectedReviewer] = useState<string>('');
  const [selectedAssignto, setSelectedAssignTo] = useState<string>('');
  const [showNextReviewerModal, setShowNextReviewerModal] = useState(false);
  const [showAssignedToModal, setShowAssignedToModal] = useState(false);
  const [filterModalColumnName, setFilterModalColumnName] = useState("");
  const [filterModalModuleName, setFilterModalModuleName] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [propertyName, setPropertyName] = useState<string>("");
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  let [filteredReviewerList] = useState<ValidValues[]>([]);
  let [filteredAssignToList] = useState<ValidValues[]>([]);

  const onApprove = async () => {
    processApproval(approveAction, nextReviewer, assignedTo);
  };

  const onReview = async () => {
    processApproval(reviewAction, nextReviewer, assignedTo);
  };

  const onReject = async () => {
    processApproval(rejectAction, nextReviewer, assignedTo);
  };

  if (metadata.fields['wo_work_order.next_reviewer'].ElementType !== 'ISS') {
    filteredReviewerList = reviewerValidData.filter(card =>
      card.Code.toLowerCase().includes(filter.toLowerCase()) || card.Description.toLowerCase().includes(filter.toLowerCase())
    );
  }

  if (metadata.fields['wo_work_order.assigned_to'].ElementType !== 'ISS') {
    filteredAssignToList = assignToValidData.filter(card =>
      card.Code.toLowerCase().includes(filter.toLowerCase()) || card.Description.toLowerCase().includes(filter.toLowerCase())
    );
  }

  const handleEmployeeSearch = (e: CustomEvent, cardPropertyName: string) => {
    let newVal = (e.target as HTMLInputElement)?.value;
    setPropertyName(cardPropertyName);
    if (cardPropertyName === "NextReviewer") {
      setNextReviewer(newVal);
    }
    else {
      setAssignedTo(newVal);
    }
    if (newVal.length > 2) {
      findEmployee(newVal).then((response) => {
        if (response.status === 200) {
          if (Object.keys(response.data).length !== 0) {
            popover.current!.event = e;
            setPopoverOpen(true);
          } else if (popoverOpen) {
            setPopoverOpen(false);
          }
          let empSearchArray = [];
          const employees = Object.entries(response.data).map(([id, name]) => ({
            EmployeeId: id,
            EmployeeName: name
          }));
          for (let search of employees) {
            empSearchArray.push(search.EmployeeId);
          }
          setSearchResults(empSearchArray);
        }
      });
    } else {
      if (popoverOpen) {
        setPopoverOpen(false);
      }
      if (searchResults.length > 0) {
        setSearchResults([]);
      }
    }
  };

  useEffect(() => {
    setReviewValidData(metadata.fields['wo_work_order.next_reviewer'].ValidValues);
    setAssignedToValidData(metadata.fields['wo_work_order.assigned_to'].ValidValues);
  }, []);

  const processApproval = async (
    action: string,
    nextReviewer?: string | null,
    assignedTo?: string | null
  ) => {
    setShowLoading(true);
    const data: ProcessWorkOrderActionListData = {
      RequestObject: {
        WorkOrderActionListData: [
          {
            WoBase: cardHeader,
            Status: action === approveAction ? "30" : null,
            Action: action,
            NextReviewer: nextReviewer,
            AssignedTo: assignedTo,
            Comments: comments,
          },
        ],
      },
      Confirmation: {},
    };
    changeStatus(data).then((response) => {
      handleStatusMessage(
        action,
        response.data?.WoResultsList[0]?.WoResult?.MessageList[0]?.Text
      );
      setShowLoading(false);
    });
  };

  const handleStatusMessage = async (action: string, status: string) => {
    if (action === approveAction) {
      return approve(status);
    }
    return action === reviewAction ? review(status) : reject(status);
  };

  const handleRadioChange = (e: any, card: any) => {
    if (card.PropertyName === 'NextReviewer') {
      setSelectedReviewer(e.detail.value);
    }
    else {
      setSelectedAssignTo(e.detail.value);
    }
  };

  const handleOk = (e: any, card: any) => {
    if (card.PropertyName === 'NextReviewer') {
      setNextReviewer(selectedReviewer);
      setShowNextReviewerModal(false);
    }
    else {
      setAssignedTo(selectedAssignto);
      setShowAssignedToModal(false);
    }
    setFilter('');
  };

  const handleClose = (e: any, card: any) => {
    if (card.PropertyName === 'NextReviewer') {
      setSelectedReviewer(selectedReviewer);
      setShowNextReviewerModal(false);
    }
    else {
      setSelectedAssignTo(selectedAssignto);
      setShowAssignedToModal(false);
    }
    setFilter('');
  };

  const resetSearchState = () => {
    setFilterModalColumnName('');
    setFilterModalModuleName('');
  };

  const onFilterModalConfirm = (selectedItem: string | number | null | undefined) => {
    setParameter(filterModalColumnName, selectedItem);
    setIsFilterModalOpen(false);
    resetSearchState();
  };

  const setParameter = (
    paramKey: string,
    paramValue: string | number | null | undefined
  ) => {
    if (paramKey === 'employee_id') {
      if (propertyName === "NextReviewer") {
        setNextReviewer(paramValue as string);
      }
      else {
        setAssignedTo(paramValue as string);
      }
    }
  };

  return (
    <IonModal isOpen={isOpen} backdropDismiss={backdropDismiss}>
      <FilterModal
        isOpen={isFilterModalOpen}
        moduleName={filterModalModuleName}
        cancel={() => setIsFilterModalOpen(false)}
        confirm={onFilterModalConfirm}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {translations["lbl_mob_wo_approval"] || "Work Order Approval"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          {getAllFields(metadata).map((card) => {
            card.PropertyName = card.TableName === "eq_locations" && card.PropertyName === "Description" ? "EquipmentDescription" : card.PropertyName;
            const data = workOrder[card.PropertyName as keyof WorkOrder];
            return (
              <>
                {card.IdText === "lbl_woworkorder_description" ? (
                  <IonCardHeader>
                    <IonCardTitle class="card-title">{cardHeader}</IonCardTitle>
                    <IonCardSubtitle>{description}</IonCardSubtitle>
                  </IonCardHeader>
                ) : card.PropertyName === "LongDescription" ? (
                  <IonCardContent class="card-description">
                    {longDescription}
                  </IonCardContent>
                ) : card.PropertyName !== "CfWorkOrder" &&
                  card.PropertyName !== "Comments" &&
                  card.PropertyName !== "NextReviewer" &&
                  card.PropertyName !== "AssignedTo" ? (
                  <IonGrid class="card-details">
                    <IonRow>
                      <IonCol>{card.TranslatedIdText}</IonCol>
                      <IonCol>
                        {typeof data === "number"
                          ? data
                          : formatData(data, card)}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </IonCard>
        <IonGrid class="comment-title">
          <IonRow>
            <IonCol>
              <IonText>
                <h3 className="ion-no-margin">
                  {translations["lbl_incyclecountbatch_comment"] || "Comment"}
                </h3>
              </IonText>
            </IonCol>
            <IonCol>
              <IonButton
                class="log"
                fill="clear"
                size="small"
                disabled={woActivity.length === 0}
                onClick={() => {
                  setShowLogActivity(true);
                }}
              >
                <IonIcon slot="start" icon={timerOutline} size="small" />
                {translations["lbl_log_activity"] || "Log Activity"}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonCard>
          <IonTextarea
            class="approval-textarea"
            placeholder={translations["lbl_enter comments"] || "Enter Comments"}
            autoGrow={true}
            onIonChange={(ev) => {
              setComments(String(ev.detail.value));
            }}
          ></IonTextarea>
        </IonCard>
        {getAllFields(metadata).map((card) => {
          if (card.PropertyName === "NextReviewer" || card.PropertyName === "AssignedTo") {
            if (card.ElementType === "Dropdown") {
              return (
                <React.Fragment>
                  <IonItem onClick={() => card.PropertyName === "NextReviewer" ? setShowNextReviewerModal(true) : setShowAssignedToModal(true)}>
                    <IonLabel>
                      {card.TranslatedIdText}
                    </IonLabel>
                    <IonLabel slot="end">{
                      card.PropertyName === "NextReviewer" ? nextReviewer : assignedTo}
                    </IonLabel>
                    <IonIcon icon={chevronDownOutline} slot="end" />
                  </IonItem>
                  <IonModal isOpen={card.PropertyName === "NextReviewer" ? showNextReviewerModal : showAssignedToModal}>
                    <IonHeader>
                      <IonToolbar>
                        <IonTitle>{card.PropertyName === "NextReviewer" ? "Select Reviewer" : "Select Assigned To"}</IonTitle>
                        <IonButtons slot="end">
                          <IonButton onClick={e => handleClose(e, card)}>Close</IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <IonItem>
                        <IonInput
                          placeholder={card.PropertyName === "NextReviewer" ? "Filter Reviewers" : "Filter Assigned To"}
                          onIonChange={e => setFilter(e.detail.value!)}
                          value={filter}
                        ></IonInput>
                        <IonIcon
                          slot="end"
                          ios={filterCircleOutline}
                          md={filterCircleSharp}
                        ></IonIcon>
                      </IonItem>
                      <IonRadioGroup value={card.PropertyName === "NextReviewer" ? selectedReviewer : selectedAssignto} onIonChange={e => handleRadioChange(e, card)}>
                        {card.PropertyName === "NextReviewer" ?
                          filteredReviewerList.map((card, index) => (
                            <IonItem key={index}>
                              <IonLabel>{card.Code} - {card.Description}</IonLabel>
                              <IonRadio slot="start" value={card.Code} />
                            </IonItem>
                          ))
                          :
                          filteredAssignToList.map((card, index) => (
                            <IonItem key={index}>
                              <IonLabel>{card.Code} - {card.Description}</IonLabel>
                              <IonRadio slot="start" value={card.Code} />
                            </IonItem>
                          ))
                        }
                      </IonRadioGroup>
                    </IonContent>
                    <IonFooter>
                      <IonButton expand="block" onClick={e => handleOk(e, card)}>OK</IonButton>
                      <IonButton fill="outline" expand="block" onClick={e => handleClose(e, card)}>Cancel</IonButton>
                    </IonFooter>
                  </IonModal>
                </React.Fragment>
              );
            }
            else if (card.ElementType === "ISS") {
              return (
                <IonItem>
                  <IonLabel>
                    {card.TranslatedIdText}
                  </IonLabel>
                  <IonInput
                    class="ion-text-end"
                    value={card.PropertyName === "NextReviewer" ? nextReviewer : assignedTo}
                    onIonInput={(ev) => {
                      onFilterModalConfirm(ev.target.value);
                      handleEmployeeSearch(ev, card.PropertyName);
                    }}
                  ></IonInput>
                  <IonIcon
                    slot="end"
                    ios={searchOutline}
                    md={searchSharp}
                    onClick={async () => {
                      resetSearchState();
                      setFilterModalColumnName('employee_id');
                      const response: ApiResponse = await getValueSearchDetails(
                        {
                          tableName: 'em_employee',
                          columnName: 'employee_id',
                        }
                      );
                      if (response.status === 200) {
                        setFilterModalModuleName(response.data.QueryWizardModuleName);
                        setPropertyName(card.PropertyName);
                        setIsFilterModalOpen(true);
                      }
                    }}
                  ></IonIcon>
                </IonItem>
              );
            }
          }
        })}
        <IonButton expand="block" onClick={onApprove}>
          {translations["lbl_approve"] || "Approve"}
        </IonButton>
        <IonButton fill="outline" expand="block" onClick={onReview}>
          {translations["lbl_review"] || "Review"}
        </IonButton>
        <IonButton
          fill="outline"
          color="danger"
          expand="block"
          onClick={onReject}
        >
          {translations["lbl_reject"] || "Reject"}
        </IonButton>
        <WOApprovalLogActivity
          isOpen={showLogActivity}
          dismiss={() => setShowLogActivity(false)}
          setShowLoading={setShowLoading}
          description={description}
          woActivity={woActivity}
        />
      </IonContent>
      <ISSPopover
        popover={popover}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setElementValue={propertyName === "NextReviewer" ? setNextReviewer : setAssignedTo}
      />
    </IonModal>
  );
};

export default WOApprovalModal;
