import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonLoading,
  IonModal,
  IonToast,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeParam } from "../util/ApiHelper";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import {
  getAllFields,
  MeterReadings,
  MeterReadingsMetadata,
} from "../models/workorders/MeterReading";
import "./MeterReadings.css";
import OnlineStatus from "./OnlineStatus";
import { TranslationsContext } from "../util/Translations";
import { getColumnsWithGroup } from "../api/Windows";
import { getMeters } from "../api/Meters";
import { postMeterReadings, putMeterReadings } from "../api/MeterReadings";

interface MeterReadingModalProps {
  isOpen: boolean;
  cancel: () => void;
  confirm: (meterReading: any) => void;
  readDate?: string;
  readTime?: string;
  reading?: number;
  comments?: string;
  equipSerial: string;
  setEquipSerial: (equipSerial: string) => void;
  isSerial: string;
  setIsSerial: (isSerial: string) => void;
  getEquipSerial: () => void;
}

const MeterReadingsModal: React.FC<MeterReadingModalProps> = (props) => {
  const {
    isOpen,
    cancel,
    confirm,
    readDate,
    readTime,
    reading,
    comments,
    equipSerial,
    isSerial,
    getEquipSerial,
  } = props;
  const { translations } = useContext(TranslationsContext);
  const { workOrderId, meter } = useParams<{
    workOrderId: string;
    meter: string;
  }>();
  let displayMeter = decodeParam(meter);
  const [metadata, setMetadata] = useState<MeterReadingsMetadata>();
  const [meterReading, setMeterReading] = useState<MeterReadings[]>([]);
  const [elementType, setElementType] = useState<string>();
  const [validValues, setValidValues] = useState<string>();
  let [lastReading, setLastReading] = useState<string | number>("");
  let [historyReading, setHistoryReading] = useState<number>();
  let [textHistoryReading, setTextHistoryReading] = useState<string>();
  let [meterUOM, setMeterUOM] = useState<string>();
  let [historyComments, setHistoryComments] = useState<string>();

  const [showLoading, setShowLoading] = useState(false);
  let date = new Date();
  let convertDate: string;
  if (readDate) {
    convertDate = decodeURIComponent(readDate);
    date = new Date(convertDate);
  }
  const [showToast, setShowToast] = useState(false);
  let [errorMessage, setErrorMessage] = useState<string>();

  const onCancel = () => {
    // Clear modal data on cancel
    if (readDate && readTime) {
      setHistoryReading(reading);
      setHistoryComments(comments);
    } else {
      setHistoryReading(0);
      setHistoryComments("");
    }
    cancel();
  };

  const metadataQuery = async () => {
      const data = {
        IncludeValidValues: false,
        IncludeValidValuesExceptions: [],
        IsReadOnly: true,
        ValidValueFilters: [],
        WindowName: "mob_meter_new",
        ContextPKey: {
          Plant: await getCurrentPlant(),
          WoBase: "",
          WoTask: "  ",
          WoSubtask: "  ",
        },
      };
      getColumnsWithGroup(data).then((response) => {
        setMetadata(response.data);
      });
  };

  const meterReadingQuery = () => {
    getMeters({
      equipSerialId: equipSerial,
      equipSerialInd: isSerial,
      meter: displayMeter,
    }).then((response) => {
      let correctMeter = response.data.find((meter: { Meter: string }) => {
        return meter.Meter === displayMeter;
      });
      setMeterReading(correctMeter);
      setElementType(correctMeter.ElementType);
      setLastReading(correctMeter.LastReading);
      setMeterUOM(correctMeter.MeterUom);
      setValidValues(correctMeter.ReadingValidValues);
    });
  };

  const formatTimeWithMillisecondsUTC = (date: Date) => {
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  async function saveMeterReading() {
    const data = {
      Reading: textHistoryReading ?? historyReading ?? "",
      PmScheduleMeterFlag: false,
      ElementType: "N",
      Comments: historyComments,
      ReportedOnWoBase: "",
      ReportedOnWoTask: "",
      ReportedOnWoSubTask: "",
      MeterUom: meterUOM,
      EquipSerialId: equipSerial,
      EquipSerialInd: isSerial,
      Meter: displayMeter,
      ReadingDate: date.toLocaleDateString(),
      ReadingTime: !!readTime ? readTime : formatTimeWithMillisecondsUTC(date),
    };

    const reads: MeterReadings[] = [];
    if (readDate && readTime) {
      putMeterReadings(data).then((response) => {
        reads.push(data);
        if (response.status !== 200) {
          setShowToast(true);
          setErrorMessage(response.data);
        } else {
          confirm(meterReading);
          setMeterReading(reads);
          setHistoryReading(0);
          setHistoryComments(comments);
          setLastReading(textHistoryReading ?? historyReading ?? "");
        }
      });
    } else {
      postMeterReadings(data).then((response) => {
        reads.push(data);
        if (response.status !== 200) {
          setShowToast(true);
          setErrorMessage(response.data);
        } else {
          confirm(meterReading);
          setMeterReading(reads);
          setHistoryReading(0);
          setHistoryComments("");
          setLastReading(textHistoryReading ?? historyReading ?? "");
        }
      });
    }
  }

  useEffect(() => {
    getEquipSerial();
    //Reset data when switching meter readings.
    if (reading) {
      setHistoryReading(reading);
    } else {
      setHistoryReading(0);
    }
    if (comments) {
      setHistoryComments(comments);
    } else {
      setHistoryComments("");
    }
    setMeterReading([]);
    // Show loading symbol
    setShowLoading(true);
    //Run api calls to get metadata and meter reading.
    metadataQuery().then(() => {
      if (equipSerial && isSerial) {
        meterReadingQuery();
      }
      // Hide loading symbol once all the calls are done
      setShowLoading(false);
    });
  }, [
    equipSerial,
    displayMeter,
    workOrderId,
    lastReading,
    meterUOM,
    reading,
    comments,
  ]);

  return (
    <IonModal isOpen={isOpen} className="meter-readings">
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          {!!readDate && !!readTime ? (
            <IonTitle>
              {translations["lbl_edit_reading"] || "Edit Meter Reading"}
            </IonTitle>
          ) : (
            <IonTitle>
              {translations["lbl_add_reading"] || "Add Meter Reading"}
            </IonTitle>
          )}
          <IonButtons slot="end">
            <IonButton expand="full" onClick={() => saveMeterReading()}>
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <OnlineStatus />
      </IonHeader>
      <IonContent fullscreen>
        {!!metadata && !!meterReading && (
          <>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              position="top"
            />
            <IonCard>
              <IonCardContent>
                <IonList lines="none">
                  {getAllFields(metadata).map((meterField: any) => {
                    const validValueList = validValues?.split(",");
                    return (
                      <IonItem
                        key={meterField.IdText}
                        className="labeled-inputs"
                      >
                        <IonLabel
                          position="stacked"
                          color="dark"
                          className="read-only-input-label"
                        >
                          {meterField.TranslatedIdText}
                        </IonLabel>
                        {meterField.PropertyName === "EquipSerialId" ? (
                          <IonInput
                            readonly
                            class="read-only-input"
                            value={equipSerial}
                          ></IonInput>
                        ) : meterField.PropertyName === "Description" ? (
                          <IonInput readonly class="read-only-input"></IonInput>
                        ) : meterField.PropertyName === "Meter" ? (
                          <IonInput
                            readonly
                            class="read-only-input"
                            value={displayMeter}
                          ></IonInput>
                        ) : meterField.PropertyName === "LastReading" ? (
                          <IonInput
                            readonly
                            class="read-only-input"
                            value={lastReading}
                          ></IonInput>
                        ) : meterField.PropertyName === "Reading" &&
                          elementType === "T" ? (
                          validValueList && validValueList.length > 0 ? (
                            <IonSelect
                              interface="popover"
                              class="editable-select-input"
                              value={historyReading}
                              onIonChange={(ev) => setHistoryReading(ev.detail.value)}
                            >
                              {validValueList.map((list: any, index) => (
                                <IonSelectOption key={index}>
                                  {list}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          ) : (
                            <IonInput
                              class="editable-input"
                              value={textHistoryReading}
                              onIonChange={(ev) => {
                                if (
                                  ev.detail.value === null ||
                                  ev.detail.value === undefined
                                )
                                  return;
                                setTextHistoryReading(ev.detail.value);
                              }}                            />
                          )
                        ) : meterField.PropertyName === "Reading" &&
                          elementType === "N" ? (
                          <IonInput
                            class="editable-input"
                            type = "number"
                            value={historyReading}
                            onIonChange={(ev) => {
                              if (
                                ev.detail.value === null ||
                                ev.detail.value === undefined
                              )
                                return;
                              setHistoryReading(+ev.detail.value);
                            }}
                          ></IonInput>
                        ) : meterField.PropertyName === "MeterUom" ? (
                          <IonInput
                            readonly
                            class="read-only-input"
                            value={meterUOM}
                          ></IonInput>
                        ) : meterField.PropertyName === "Comments" ? (
                          <IonInput
                            class="editable-input"
                            value={historyComments}
                            onIonChange={(ev) => {
                              if (
                                ev.detail.value === null ||
                                ev.detail.value === undefined
                              )
                                return;
                              setHistoryComments(ev.detail.value);
                            }}
                          ></IonInput>
                        ) : (
                          <></>
                        )}
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>
          </>
        )}
      </IonContent>
    </IonModal>
  );
};

export default MeterReadingsModal;
