import { FileOpener } from "@capacitor-community/file-opener";
import { Photo } from "@capacitor/camera";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { isPlatform } from "@ionic/core";
import { ViewOptions } from "../hooks/useDocumentManager";
import { DocumentProvider, WoDocumentUpload } from "../models/workorders/Documents";
import { ErrorCodes, ErrorInfo } from "./ErrorHelper";
import { ApiResponse } from "../models/api/ApiResponse";
import { downloadDocument, linkDocToWorkOrder, uploadDocument } from "../api/Documents";
import { translateMessageList } from "../api/Languages";
import { getUserSettings } from "./UserHelper";

const DOCUMENTS_DIR = "wo_documents";

export const upload = async (fileName: string, contentType: string, base64String: string, woNumber: string): Promise<any> => {
    const respUpload: ApiResponse = await uploadDocument({
        Filename: fileName,
        ContentType: contentType,
        DocContentBase64: base64String
    });

    if (respUpload.status !== 200 || respUpload.isError) {
        throw new ErrorInfo(ErrorCodes.Document_Upload_Failed, respUpload.data);
    }
    const docUploaded: WoDocumentUpload = respUpload.data;
    //Link the file to the work order
    const respLinkDoc2Wo: ApiResponse = await linkDocumentToWorkOrder(woNumber, docUploaded);

    if (respLinkDoc2Wo.status !== 200) {
        throw new ErrorInfo(ErrorCodes.Document_Upload_Failed, respLinkDoc2Wo.data);
    }
    //get translated message for file upload
    const respMsgList: ApiResponse = await translateMessageList([{ "MessageId": "DocumentUploaded", "MessageParameters": [] }]);

    if (respMsgList.status !== 200) {
        console.log('Invalid TranslateMessageList request: ' + respMsgList.status);
        throw new ErrorInfo(ErrorCodes.Document_Upload_Failed, "Document uploaded successfully and linked to the work order.");
    }
    return respMsgList.data.DocumentUploaded.MessageText;
};

export const linkDocumentToWorkOrder = async (woNumber: string, document: WoDocumentUpload) => {
    //fetch document provider data for providerId
    return await linkDocToWorkOrder({
        DocId: document.DocumentId.toString(),
        PrintWithWoFlag: false,
        WoNumber: woNumber,
        ProviderId: await getDocumentProviderId()
    });
};

export const getDocumentProviderId = async () => {
    const userSettings = await getUserSettings();
    if (userSettings?.DocumentProviders) {
        if (userSettings.DocumentProviders) {
            const provider = userSettings.DocumentProviders.find((p: DocumentProvider) => p.Name === "Aptean DMS");
            if (!!provider) {
                return provider.Providerid;
            }
        }
    }
    return 0;
};

export const downloadAndView = async (options: ViewOptions) => {
    const fileResponse: ApiResponse = await downloadDocument(options.Document.DownloadURL!);
    if (!fileResponse || !fileResponse.headers) {
        return '';
    }
    let fileName = options.Document.Description;
    if (fileResponse!.status === 200) {
        //get content-type from response
        let contentType = (isPlatform('hybrid')) ? fileResponse!.headers['Content-Type'] : fileResponse!.headers['content-type'];
        const contentDisposition = (isPlatform('hybrid')) ? fileResponse.headers["Content-Disposition"] : fileResponse.headers["content-disposition"];
        //read filename from content-disposition
        if(contentDisposition !== undefined){
            fileName = contentDisposition.split(';')[1].split('=')[1].replaceAll('\"', '');
        }
        if (!fileName) {
            fileName = `${options.WoNumber}_${options.Document.DocumentId}.${getFileExtension(contentType)}`;
        } else if (fileName.lastIndexOf('.') === -1) {
            fileName = `${fileName}.${getFileExtension(contentType)}`;
        }

        //if content-type is octet-stream, change to application/[file-extn]
        if (contentType === "application/octet-stream" && !!fileName) {
            contentType = `video/${fileName.substring(fileName.lastIndexOf('.') + 1)}`;
        }

        // Ensure correct content type for images
        if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
            contentType = 'image/jpeg';
        } else if (fileName.endsWith('.png')) {
            contentType = 'image/png';
        }

        const data = await fetch(`data:${contentType};base64,${fileResponse!.data}`);
        const blob = await data.blob();      
        //save file to the device to open with FileOpener
        if (isPlatform('hybrid')) {
            const base64Data = await convertBlobToBase64(blob) as string;
            const localPath = `${DOCUMENTS_DIR}/${fileName}`;

            const savedFile = await Filesystem.writeFile({
                data: base64Data,
                path: localPath,
                directory: Directory.Data,
                recursive: true
            });

            await FileOpener.open({ filePath: savedFile.uri, contentType: contentType }).then(() => {
                console.log('File ' + savedFile.uri + ' opened');
            }).catch((reason) => {
                console.log('FileOpener error: ' + reason);

                return reason;
            }).finally(() => {
                //delete the file
                deleteFile(localPath);
            });
        } else {
            const fileURL = URL.createObjectURL(blob);
            //using location.assign to fix problem with Safari
            //verified in Safari, Edge and Chrome
            window.location.assign(fileURL);
        }
    } else {
        return `${fileResponse!.status}: ${fileResponse!.data}`;
    }
    return "";
};

export const readDataAsBase64 = async (photo: Photo) => {
    if (isPlatform('hybrid')) {
        const file = await Filesystem.readFile({
            path: photo.path!
        });
        return file.data;
    } else {
        const resp = await fetch(photo.webPath!);
        const blob = await resp.blob();

        return await convertBlobToBase64(blob) as string;
    }
};

export const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

const deleteFile = async (filePath: string) => {
    //delete downloaded files asynchronously
    //wait 30s to avoid issue in opening the file in slow devices
    setTimeout(async () => {
        try {
            Filesystem.deleteFile({
                path: filePath,
                directory: Directory.Data
            });
        } catch (error) {
            console.log(`Error deleting file ${filePath}: ${error}`);
        }
    }, 30000);
};

const getFileExtension = (contentType: string) => {
    const type = contentType.substring(contentType.lastIndexOf('/') + 1);
    switch (type) {
        case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return "xlsx";
        case "vnd.openxmlformats-officedocument.wordprocessingml.document":
            return "docx";
        case "vnd.openxmlformats-officedocument.presentationml.presentation":
            return "pptx";
        case "msword":
            return "doc";
        case "vnd.ms-excel":
            return "doc";
        case "vnd.ms-powerpoint":
            return "ppt";
        case "quicktime":
            return "mov";
    }
    if (type) {
        return type;
    }
    return "txt";
};
